<template>
  <section>
    Регламент
  </section>
</template>

<script>
export default {
  name: 'Rules'
}
</script>